/**
 * File for the `Customer Insights History` widget form.
 */

import { Col, Form, Row, Select, DatePicker, Divider, Switch, InputNumber, Input } from 'antd';
import { get, map, isUndefined, isEmpty, capitalize, forEach } from 'lodash';
import React, { useState } from 'react';
import {
    populatePopoverContainer, getTranslatedText
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import SelectReadonly from '../FormComponents/SelectReadonly';
import OrganisationWidgetCommonFilters from './organisation/OrganisationWidgetCommonFilters';
import {
    chartWidgetDisplayTypes,
    widgetDateRangeValues,
    salesDateRangeOptionsComplete,
} from '../../constants/dashboards';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { dateSelectOptions } from '../../constants/invoicesSortAndFilters';
import { customerFieldIndicator } from '../common/FilterBar';
import { dateFormatDDMMMYYYYSpace, dateFormatDDMMYYYYSlash, dateFormatYYYYMMDDDash, } from '../../constants/dateFormats';
import { ApplicationState } from '../../store';
import { AppCommonState } from '../../store/common/types';
import { getCustomerInsightsHistoryWidgetFilters } from '../../constants/customersSortAndFilters';
import InputAutoCompleteWithButtonDashboard from '../common/InputAutoCompleteWithButtonDashboard';

const { Item: FormItem } = Form;
const { Option } = Select;

interface IProps {
    widgetDetails: DynamicObject;
    getFieldDecorator: any;
    drawerRef: any;
    formDisabled?: boolean;
    isOrgView?: boolean;
    setFieldsValue: any;
    getFieldValue: any;
}

export const displayColumnIndicator = 'DisplayColumn---';
export const showCustomFieldsIndicator = 'ShowCustomFields--';
export const displayRowCountOptions = [5, 10, 15, 20];
export const defaultStartDate: any = moment().format('YYYY-01-01T00:00:00');
export const defaultEndDate: any = moment().format('YYYY-12-31T23:59:59');

export const displayColumnOptions = {
    CustomerCode: {
        label: 'Customer code',
        value: 'CustomerCode',
        defaultChecked: true,
        OrgViewOnly: false,
        orderNumber: 13
    },
    CustomerName: {
        label: 'Customer name',
        value: 'CustomerName',
        defaultChecked: true,
        OrgViewOnly: false,
        orderNumber: 14
    },
    January: {
        label: 'January',
        value: 'January',
        defaultChecked: true,
        OrgViewOnly: false,
        orderNumber: 1
    },
    February: {
        label: 'February',
        value: 'February',
        defaultChecked: true,
        OrgViewOnly: false,
        orderNumber: 2
    },
    March: {
        label: 'March',
        value: 'March',
        defaultChecked: true,
        OrgViewOnly: false,
        orderNumber: 3
    },
    April: {
        label: 'April',
        value: 'April',
        defaultChecked: true,
        OrgViewOnly: false,
        orderNumber: 4
    },
    May: {
        label: 'May',
        value: 'May',
        defaultChecked: true,
        OrgViewOnly: false,
        orderNumber: 5
    },
    June: {
        label: 'June',
        value: 'June',
        defaultChecked: true,
        OrgViewOnly: false,
        orderNumber: 6
    },
    July: {
        label: 'July',
        value: 'July',
        defaultChecked: true,
        OrgViewOnly: false,
        orderNumber: 7
    },
    August: {
        label: 'August',
        value: 'August',
        defaultChecked: true,
        OrgViewOnly: false,
        orderNumber: 8
    },
    September: {
        label: 'September',
        value: 'September',
        defaultChecked: true,
        OrgViewOnly: false,
        orderNumber: 9
    },
    October: {
        label: 'October',
        value: 'October',
        defaultChecked: true,
        OrgViewOnly: false,
        orderNumber: 10
    },
    November: {
        label: 'November',
        value: 'November',
        defaultChecked: true,
        OrgViewOnly: false,
        orderNumber: 11
    },
    December: {
        label: 'December',
        value: 'December',
        defaultChecked: true,
        OrgViewOnly: false,
        orderNumber: 12
    },
};



const CustomersInsightsHistoryWidgetFields: React.FC<IProps> = ({
    drawerRef,
    widgetDetails,
    formDisabled,
    getFieldDecorator,
    isOrgView,
    getFieldValue,
    setFieldsValue,
}: IProps) => {

    const customerLabel = useSelector(getCustomerUILabel);

    const appState: AppCommonState = useSelector(
        (state: ApplicationState) => state.app
    );

    const [searchFilters, setSearchFilters] = useState<DynamicObject>({});

    /**
    * Function that updates the search input filters.
    * @param filterName - name of filter item
    * @param value - string value entered
    */
    const changeSearchFilter = (filterName: string, value: any) => {
        updateSearchFiltersObject({
            [filterName]: value,
        });
    };

    /**
     * Common function for updating the serachFilters object from state.
     * @param searchFiltersObject
     */
    const updateSearchFiltersObject = (searchFiltersObject: DynamicObject) => {
        setSearchFilters({
            ...searchFilters,
            ...searchFiltersObject,
        });
    };

        /**
     * Function that will be triggered when selecting a date range.
     * @param rangeType  - From and To - based on UI mockup
     * @param filterName - name of filter
     */
    const selectDateRange = (rangeType: 'From' | 'To', filterName: string) => {
        return (dateValue: any) => {
            const searchFilterParent = get(searchFilters, filterName);
            const newSearchFilterParent = {
                ...searchFilterParent,
                [rangeType]: dateValue,
            };
            updateSearchFiltersObject({
                [filterName]: newSearchFilterParent,
            });
        };
    };

        /**
     * Function responsible for setting the days range filter values.
     * @param rangeType - Last, Next
     * @param filterName - name of filter
     * @param daysType  - From, To, or not defined
     */
    const selectDaysRange = (
        rangeType: 'Last' | 'Next',
        filterName: string,
        daysType: 'From' | 'To' | undefined = undefined
    ) => {
        return (daysValue: number | undefined) => {
            if (daysType) {
                const searchFilterParent = get(searchFilters, filterName);
                if (get(searchFilterParent, daysType)) {
                    if (rangeType === 'Last') {
                        delete searchFilterParent[daysType].Next;
                    } else {
                        delete searchFilterParent[daysType].Last;
                    }
                }
                const newSearchFilterParent = {
                    ...searchFilterParent,
                    [daysType]: {
                        [rangeType]: daysValue,
                    },
                };

                updateSearchFiltersObject({
                    [filterName]: newSearchFilterParent,
                });
            } else {
                const searchFilterParent = get(searchFilters, filterName);
                if (rangeType === 'Last') {
                    delete searchFilterParent.Next;
                } else {
                    delete searchFilterParent.Last;
                }
                const newSearchFilterParent = {
                    ...searchFilterParent,
                    [rangeType]: daysValue,
                };

                updateSearchFiltersObject({
                    [filterName]: newSearchFilterParent,
                });
            }
        };
    };

    /**
     * Function for handling the select onChange event.
     * @param selectValue - selected value on dropdown
     * @param filter - name of the filter
     * @param fieldKey - key for the component
     * @param stateName - state name of the filter
     */
    const handleSelectOnChange = (
        selectValue: string,
        filter: any,
        fieldKey: string,
        stateName: string
    ) => {
        const newFilterValue: any = {
            value: selectValue,
        };
        if (
            selectValue ===
            dateSelectOptions.CUSTOM_DAYS_RANGE
        ) {
            const customType =
                filter.filterOptionsCustomTypes[selectValue];

            if (customType === 'all-days-range-from-to') {
                if (get(widgetDetails, `${fieldKey}${"--RangeTypeFrom"}`) === "Last") {
                    newFilterValue.From = {
                        Last: get(widgetDetails, `${fieldKey}${"--Last--From"}`)
                    }
                }
                else {
                    newFilterValue.From = {
                        Next: get(widgetDetails, `${fieldKey}${"--Next--From"}`)
                    }
                }

                if (get(widgetDetails, `${fieldKey}${"--RangeTypeTo"}`) === "Last") {
                    newFilterValue.To = {
                        Last: get(widgetDetails, `${fieldKey}${"--Last--To"}`)
                    }
                }
                else {
                    newFilterValue.To = {
                        Next: get(widgetDetails, `${fieldKey}${"--Next--To"}`)
                    }
                }
            }
            else if (customType === 'all-days-range-from-to-last') {
                newFilterValue.From = {
                    Last: get(widgetDetails, `${fieldKey}${"--Last--From"}`)
                };
                newFilterValue.To = {
                    Last: get(widgetDetails, `${fieldKey}${"--Last--To"}`)
                };
            }
            else if (customType === 'last-days-range') {
                newFilterValue.Last = get(widgetDetails, `${fieldKey}${"--Last"}`);
            }
            else if (customType === 'all-days-range') {
                if (get(widgetDetails, `${fieldKey}${"--RangeType"}`) === "Last") {
                    newFilterValue.Last = get(widgetDetails, `${fieldKey}${"--Last"}`);
                }
                else {
                    newFilterValue.Next = get(widgetDetails, `${fieldKey}${"--Next"}`);
                }
            }
            else {
                newFilterValue.Last = 1;
            }
        }

        changeSearchFilter(
            stateName,
            newFilterValue
        );
    }

        /**
    * Function to populate the select/dropdown filter.
    * @param customType - nype of filter, date-range or etc
    * @param filterName - name of filter
    * @param filterFieldKey - key for the component
    * @param filterMaxDate - optional max date for filter, can be a moment object or string
    */
    const populateSelectCustomType = (
        customType: string,
        filterName: string,
        filterFieldKey: string,
        filterMaxDate?: any
    ) => {
        if (customType === 'date-range') {
            const fromDateFieldKey = `${filterFieldKey}${"--From"}`
            const toDateFieldKey = `${filterFieldKey}${"--To"}`
            let disabledDate;
            if (filterMaxDate) {
                disabledDate = (dateValue: any) => {
                    return moment(
                        moment(dateValue).format(dateFormatYYYYMMDDDash)
                    ).isAfter(
                        moment(
                            moment(filterMaxDate).format(dateFormatYYYYMMDDDash)
                        )
                    );
                };
            }
            return (
                <Col span={12} key={filterFieldKey}>
                    <FormItem label={getTranslatedText("From")}>
                        {getFieldDecorator(fromDateFieldKey, {
                            initialValue: !isEmpty(get(widgetDetails, fromDateFieldKey)) ?
                                moment(get(widgetDetails, fromDateFieldKey)) :
                                null,
                        })(
                            <DatePicker
                                className="dashboard-datepicker"
                                format={dateFormatDDMMYYYYSlash}
                                disabledDate={disabledDate}
                                placeholder={getTranslatedText("Start Date")}
                                onChange={selectDateRange('From', filterName)}
                                getCalendarContainer={populatePopoverContainer(
                                    drawerRef
                                )}
                                allowClear={true}
                            />
                        )}
                    </FormItem>
                    <FormItem label={getTranslatedText("To")}>
                        {getFieldDecorator(toDateFieldKey, {
                            initialValue: !isEmpty(get(widgetDetails, toDateFieldKey)) ?
                            moment(get(widgetDetails, toDateFieldKey)) :
                            null,
                        })(
                            <DatePicker
                                className="dashboard-datepicker"
                                format={dateFormatDDMMYYYYSlash}
                                disabledDate={disabledDate}
                                placeholder={getTranslatedText("End Date")}
                                onChange={selectDateRange('To', filterName)}
                                getCalendarContainer={populatePopoverContainer(
                                    drawerRef
                                )}
                                allowClear={true}
                            />
                        )}
                    </FormItem>
                </Col>
            );
        } else if (customType === 'all-days-range-from-to-last') {
            const daysRangeType = ['Last'];
            const rangeTypeSelectedFrom = 'Last';
            const rangeTypeSelectedTo = 'Last';

            const daysValueFrom = get(
                searchFilters,
                `${filterName}.From.${rangeTypeSelectedFrom}`
            );
            const daysValueTo = get(
                searchFilters,
                `${filterName}.To.${rangeTypeSelectedTo}`
            );

            return (
                <Col span={12} key={filterFieldKey}>
                    {map(['From', 'To'], (daysType: 'From' | 'To') => {
                        let typeLabel = 'Selected from';
                        let rangeTypeSelected: 'Last' = rangeTypeSelectedFrom;
                        let daysValue = daysValueFrom;

                        if (daysType === 'To') {
                            typeLabel = 'Go to';
                            rangeTypeSelected = rangeTypeSelectedTo;
                            daysValue = daysValueTo;
                        }

                        const daysTypeFieldKey = `${filterFieldKey}${"--Last--"}${daysType}`

                        return (
                            <div key={daysType}>
                                <span>{getTranslatedText(typeLabel)}</span><br />
                                <div className="dashboard-div">
                                    <FormItem>
                                        <SelectReadonly
                                            readOnly={formDisabled}
                                            onChange={(rangeType: 'Last') => {
                                                selectDaysRange(
                                                    rangeType,
                                                    filterName,
                                                    daysType
                                                )(1);
                                            }}
                                            value={rangeTypeSelected}
                                            placeholder={getTranslatedText("Type")}
                                            getPopupContainer={populatePopoverContainer(
                                                drawerRef
                                            )}
                                        >
                                            {map(
                                                daysRangeType,
                                                (rangeType: string) => (
                                                    <Option
                                                        key={rangeType}
                                                        value={rangeType}
                                                    >
                                                        {rangeType}
                                                    </Option>
                                                )
                                            )}
                                        </SelectReadonly>
                                    </FormItem>
                                    &nbsp;&nbsp;
                                    <FormItem>
                                        {getFieldDecorator(daysTypeFieldKey, {
                                            initialValue: daysValue
                                        })(
                                            <InputNumber
                                                placeholder={getTranslatedText("Count")}
                                                onChange={selectDaysRange(
                                                    rangeTypeSelected,
                                                    filterName,
                                                    daysType
                                                )}
                                                min={1}
                                            />
                                        )}
                                    </FormItem>
                                    &nbsp;&nbsp;
                                    <span>
                                        {getTranslatedText(`day${daysValue > 1 ? 's' : ''}`)}
                                    </span>
                                </div>
                            </div>
                        );
                    })
                    }
                </Col >
            );
        }
    };

    /**
     * Function that populates the date range options.
     */
    const populateSalesDateRangeOptions = () =>
    map(salesDateRangeOptionsComplete, ({ label, value }: any) => (
        <Option key={value} value={value}>
            {getTranslatedText(label)}
        </Option>
    ));

   /**
    * Function for populating the customer insights history fields filters.
    */
   const populateCustomerInsightsHistoryFieldsFilters = () => {
        
    return getCustomerInsightsHistoryWidgetFilters().map(
            (filter: DynamicObject) => {
               const fieldKey = `${customerFieldIndicator}${filter.filterStateName}`;
               const stateName = filter.filterStateName || filter.filterName;

                if (filter.filterElement === 'radio-group') {
                    return (
                        <Col span={12} key={fieldKey}>
                            <FormItem label={getTranslatedText(filter.filterName)}>
                                {getFieldDecorator(fieldKey, {
                                    initialValue: get(
                                        widgetDetails,
                                        fieldKey
                                    ),
                                })(
                                    <SelectReadonly
                                        allowClear={true}
                                        placeholder={getTranslatedText(filter.filterPlaceholder)}
                                        readOnly={formDisabled}
                                        getPopupContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    >
                                        {map(filter.filterOptions, ({ label, value }: any) => (
                                            <Option key={value} value={value}>
                                                {label}
                                            </Option>
                                        ))}
                                    </SelectReadonly>
                                )}
                            </FormItem>
                        </Col >
                    );
                }
                else if (filter.filterElement === 'select') {
                    const dateTypeFieldKey = `${fieldKey}${"--DateType"}`
                    const optionsObject: any = {};
                    forEach(filter.filterOptions, (option: string) => {
                        if (!optionsObject[option]) {
                            optionsObject[option] = option;
                        }
                    });

                    const selectCustomValueType = !isUndefined(searchFilters[stateName]) ?
                        get(searchFilters[stateName], 'value') :
                        get(widgetDetails, dateTypeFieldKey)

                    const selectCustomType = filter.filterOptionsCustomTypes[selectCustomValueType];

                    // Manually simulate onChange event on initial load
                    if ((selectCustomValueType === dateSelectOptions.CUSTOM_DATE_RANGE ||
                        selectCustomValueType === dateSelectOptions.CUSTOM_DAYS_RANGE) &&
                        isUndefined(searchFilters[stateName])) {
                        handleSelectOnChange(selectCustomValueType, filter, fieldKey, stateName);
                    }
                    return (
                        <Col span={12} key={dateTypeFieldKey}>
                            <FormItem label={getTranslatedText(filter.filterName)}>
                                {getFieldDecorator(dateTypeFieldKey, {
                                    initialValue: get(
                                        widgetDetails,
                                        dateTypeFieldKey
                                    ),
                                })(
                                    <SelectReadonly
                                        allowClear={true}
                                        readOnly={formDisabled}
                                        className="date-picker-select"
                                        placeholder={getTranslatedText(filter.filterPlaceholder)}
                                        onChange={(selectValue: any) => {
                                            handleSelectOnChange(selectValue, filter, fieldKey, stateName);
                                        }}
                                        getPopupContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    >
                                        {map(optionsObject, (option: any) => (
                                            <Option key={option} value={option}>
                                                {!isUndefined(option) ? option : getTranslatedText('Select Date Range')}
                                            </Option>
                                        ))}
                                    </SelectReadonly>
                                )}
                            </FormItem>
                            {selectCustomType &&
                                populateSelectCustomType(
                                    selectCustomType,
                                    stateName,
                                    fieldKey,
                                    filter.filterMaxDate
                                )}
                        </Col >
                    );
                }
                else if (filter.filterElement === 'input-autocomplete') {
                    const filterNameUsed =
                        filter.filterStateName === 'Customer' &&
                            !isOrgView
                            ? capitalize(customerLabel)
                            : filter.filterName;
                    const stateName = filter.filterStateName || filter.filterName;
                    return (
                        <Col span={12} key={fieldKey}>
                            <FormItem label={getTranslatedText(filterNameUsed)}>
                                <InputAutoCompleteWithButtonDashboard
                                    readOnly={formDisabled}
                                    hasNoOkButton={true}
                                    updateField={(value: string) => {
                                        changeSearchFilter(stateName, value);
                                    }}
                                    stateValue={searchFilters[stateName]}
                                    queryName={filter.filterQuery}
                                    queryFilterName={filter.filterNameQuery}
                                    filterField={filter.filterStateName}
                                    sortField={filter.filterSort}
                                    responseName={filter.filterResponse}
                                    labelField={filter.filterLabelField}
                                    placeholder={filter.filterPlaceholder}
                                    filterSubChecking={filter.filterSubChecking}
                                    queryFilterNameSub={filter.filterNameQuerySub}
                                    getFieldDecorator={getFieldDecorator}
                                    widgetDetails={widgetDetails}
                                    filterFieldKey={fieldKey}
                                />
                            </FormItem>
                        </Col>
                    );
                }
                else {
                    const filterNameUsed =
                        filter.filterStateName === 'Customer' &&
                            !isOrgView
                            ? capitalize(customerLabel)
                            : filter.filterName;
                    return (
                        <Col span={12} key={fieldKey}>
                            <FormItem label={getTranslatedText(filterNameUsed)}>
                                {getFieldDecorator(fieldKey, {
                                    initialValue: get(
                                        widgetDetails,
                                        fieldKey
                                    ),
                                })(
                                    <Input
                                        placeholder={getTranslatedText(filterNameUsed)}
                                        readOnly={formDisabled}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    );
                }    
            }
        )
    }

    /**
     * Function that populates the start and end date form input when custom date range is selected.
     */
    const populateCustomDateRangeFormItem = () => {
        if (
            getFieldValue('dateRangeSelected') ===
            widgetDateRangeValues.CUSTOM_DATE_RANGE
        ) {
            return (
                <Col span={24}>
                    <Row gutter={10} className="mt-10">
                        <Col span={12}>
                            <FormItem label={getTranslatedText("Start date")}>
                                {getFieldDecorator('customDateRangeStart', {
                                    initialValue: get(
                                        widgetDetails,
                                        'customDateRangeStart'
                                    )
                                        ? moment(
                                            get(
                                                widgetDetails,
                                                'customDateRangeStart'
                                            )
                                        )
                                        : moment(defaultStartDate),
                                    rules: [
                                        {
                                            required: true,
                                            message: getTranslatedText('Start date required!'),
                                        },
                                    ],
                                })(
                                    <DatePicker
                                        format={dateFormatDDMMMYYYYSpace}
                                        placeholder={getTranslatedText("Start date")}
                                        getCalendarContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label={getTranslatedText("End date")}>
                                {getFieldDecorator('customDateRangeEnd', {
                                    initialValue: get(
                                        widgetDetails,
                                        'customDateRangeEnd'
                                    )
                                        ? moment(
                                            get(
                                                widgetDetails,
                                                'customDateRangeEnd'
                                            )
                                        )
                                        : moment(defaultEndDate),
                                    rules: [
                                        {
                                            required: true,
                                            message: getTranslatedText('End date required!'),
                                        },
                                    ],
                                })(
                                    <DatePicker
                                        format={dateFormatDDMMMYYYYSpace}
                                        placeholder={getTranslatedText("End date")}
                                        getCalendarContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Col>
            );
        } else {
            return null;
        }
    };

    /**
     * Function that populates the columns to display switches.
     */
    const populateColumnsToDisplayOptions = () => {
        return map(
            displayColumnOptions,
            ({ label, value, defaultChecked, OrgViewOnly }: DynamicObject) => {

                if (!isOrgView && OrgViewOnly) {
                    return null;
                }
                else {
                    const displayColumnKey = `${displayColumnIndicator}${value}`;
                    const switchValue = !isUndefined(
                        get(widgetDetails, displayColumnKey)
                    )
                        ? get(widgetDetails, displayColumnKey)
                        : defaultChecked;

                    return (
                        <Col
                            key={value}
                            span={12}
                            className="switch-fields-container"
                        >
                            <FormItem label={getTranslatedText(label)}>
                                {getFieldDecorator(displayColumnKey, {
                                    valuePropName: 'checked',
                                    initialValue: switchValue,
                                })(<Switch disabled={formDisabled} />)}
                            </FormItem>
                        </Col>
                    );
                }
            }
        );
    };

     /**
     * Function for populating the row count options.
     */
    const populateRowCountOptions = () =>
        map(displayRowCountOptions, (count: number) => (
            <Option key={count} value={count}>
                {count}
            </Option>
        ));

        return (
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <Row>
                                <Col span={24}>
                                    <h3>{getTranslatedText('Filter by customer fields')}</h3>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                {populateCustomerInsightsHistoryFieldsFilters()}
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Filter on date range")}>
                            {getFieldDecorator('dateRangeSelected', {
                                initialValue:
                                    get(widgetDetails, 'dateRangeSelected') ||
                                    widgetDateRangeValues.THIS_CALENDAR_YEAR,
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText('Date range required!'),
                                    },
                                ],
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                >
                                    {populateSalesDateRangeOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                    {populateCustomDateRangeFormItem()}
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <Row>
                            <Col span={24}>
                                <h3>{getTranslatedText('Columns to display')}</h3>
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            {populateColumnsToDisplayOptions()}
                        </Row>
                    </Col>
                </Row>
                {isOrgView && (
                    <OrganisationWidgetCommonFilters
                        widgetDetails={widgetDetails}
                        getFieldDecorator={getFieldDecorator}
                        getFieldValue={getFieldValue}
                        setFieldsValue={setFieldsValue}
                        drawerRef={drawerRef}
                        isRegional
                    />
                )}
                <Divider />

               <Row>
                    <Col>
                        <FormItem label={getTranslatedText("Number of rows (Amount)")}>
                            {getFieldDecorator('rowCount', {
                                initialValue:
                                    get(widgetDetails, 'rowCount') ||
                                    get(displayRowCountOptions, '0'),
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText('Row count is required!'),
                                    },
                                ],
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    style={{ width: 80 }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                >
                                    {populateRowCountOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                </Row>
            </Col>
        </Row>
    );

};

export default CustomersInsightsHistoryWidgetFields;
