import React, { useState, useEffect } from "react";
import { Table, Row, Col, Spin, Input, Button, Icon } from "antd";
import { concat, filter, first, get, isEmpty, isEqual, isUndefined, map, uniqWith } from "lodash";
import { ApplicationState } from "../../store";
import { CompanyUserRole } from "../../store/companies/types";
import { useDispatch, useSelector } from 'react-redux';
import { CUSTOMERS_PAGE } from "../../config/tableAndPageConstants";
import { getTranslatedText } from "../../utils/commonFunctions";
import { 
    applyInvoiceHaveNoPaymentPlanFiltersAction, 
    getInvoicesHaveNoPaymentPlanRequestAction,
    resetInvoiceHaveNoPlaymentPlanFiltersAction,
    updateBatchPaymentPlanFormDataAction
} from "../../store/invoices/actions";
import { Invoice, InvoiceHaveNoPaymentPlanSelectedModel } from "../../store/invoices/types";

const tablePageSize = CUSTOMERS_PAGE.pageSize;
interface InvoiceNoPaymentPlanListComponentProps {
    invoiceIds?: string[] | [];
    isInvoicesSelectedAll?: boolean | false;
    customerIds?: string[] | [];
    excludedCustomerIds?: string[] | [];
    isCustomerSelectedAll?: boolean | false;
    handleNextStepFormClick: () => void;
    closePanel: (refreshList?: boolean) => void;
    formatCurrency?: (amount: number) => string;
    formatToParts?: (amount: number) => Intl.NumberFormatPart[];
}

const InvoiceNoPaymentPlanListComponent: React.FC<InvoiceNoPaymentPlanListComponentProps> = ({ 
    invoiceIds,
    isInvoicesSelectedAll,
    customerIds,
    isCustomerSelectedAll,
    handleNextStepFormClick,
    closePanel,
    formatCurrency,
    formatToParts
}) => {
    const dispatch = useDispatch();

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const batchPaymentPlanState = useSelector(
        (state: ApplicationState) => state.invoices.batchPaymentPlan
    );
    const batchPaymentPlanFormData = get(batchPaymentPlanState, 'formData', {});
    const invoiceStates = get(batchPaymentPlanState, 'invoices');
    const invoiceData = get(invoiceStates, 'data');
    let invoiceDataToSelectedRowKeys: InvoiceHaveNoPaymentPlanSelectedModel[] = [];
    if (!isUndefined(invoiceData) && !isEmpty(invoiceData)) {
        invoiceDataToSelectedRowKeys = invoiceData.map(
            (invoice: Invoice) => Object.assign({}, {
                InvoiceId: get(invoice, 'Id'),
                CustomerId: get(invoice, 'Customer.Id'),
                TotalAmount: get(invoice, 'AmountOwing')
            }), 
        );
    }

    const filters = get(invoiceStates, 'filters');
    const hasFilters = Object.values(filters).some(
        (value) => !isUndefined(value) && !isEmpty(value)
    );
    
    const tableCurrentPage = get(invoiceStates, 'pageData.currentPage', 0);
    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: InvoiceHaveNoPaymentPlanSelectedModel[];
        unselectedRowKeys: InvoiceHaveNoPaymentPlanSelectedModel[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
    });

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        const adjustFilters = {
            ...filters
        };
        if (!isUndefined(customerIds) && !isEmpty(customerIds)) {
            adjustFilters.CustomerIds = isCustomerSelectedAll 
                ? [] 
                : customerIds;
        }
        if (!isUndefined(invoiceIds) && !isEmpty(invoiceIds)) {
            adjustFilters.InvoiceIds = isInvoicesSelectedAll 
                ? [] 
                : invoiceIds;
        }

        return {
            filters: adjustFilters,
            pageSize,
            currentPage: currentPage
        };
    };

    /**
     * Function that handles fetching of customers (calls an action that triggers an API call).
     * @param currentPage - page dependent on scroll
     * @param pageSize - number of items in page
     */
    const fetchInvoicesHaveNoPaymentPlan = (
        currentPage = tableCurrentPage,
        pageSize = tablePageSize
    ) => {
        if (isUndefined(selectedUserCompany) || isEmpty(selectedUserCompany)) return;

        const payload = generatePayloadForRequest(currentPage, pageSize);
        dispatch(getInvoicesHaveNoPaymentPlanRequestAction(payload));
    };

    /**
     * Function called when more data from list is requested
     * by scrolling down.
     */
    const handleInvoiceData = (calling: string = '') => {
        if (isEmpty(selectedUserCompany)
            || isUndefined(selectedUserCompany)
            || invoiceStates.loading
        )
            return;
        if (!invoiceStates.pageData.hasNextPage) return;

        const nextPage = tableCurrentPage + 1;
        fetchInvoicesHaveNoPaymentPlan(nextPage);
    };

    const handleTableScroll = (event: Event) => {
        const target = event.target as HTMLTableSectionElement;
        if (!target || target.scrollTop === 0) return;

        if (target.scrollTop + target.clientHeight === target.scrollHeight) {
            handleInvoiceData();
        }
    };

    const handleClosePanel = () => {
        dispatch(resetInvoiceHaveNoPlaymentPlanFiltersAction());
        closePanel(true);
    }

    /**
     * Init Table Search
     */
    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, dataIndex, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type='primary'
                    onClick={() => handleSearch(selectedKeys, dataIndex, confirm)}
                    icon='search'
                    size='small'
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button 
                    onClick={() => handleReset(dataIndex, clearFilters)} 
                    size='small'
                    style={{ width: 90 }}
                    disabled={!hasFilters}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <Icon 
                type='search' 
                style={{ color: filtered ? '#1890ff' : undefined }} 
            />
        ),
    });

    /**
     * Init Table columns
     */
    const columns = [
        { 
            title: 'Customer Name', 
            dataIndex: 'CustomerName', 
            key: 'CustomerName',
            render: (text: any, record: Invoice, index: number) => (get(record, 'Customer.DisplayName') || ''),
            ...getColumnSearchProps('Customer')
        },
        { 
            title: 'Customer Code', 
            dataIndex: 'CustomerCode', 
            key: 'CustomerCode',
            render: (text: any, record: Invoice, index: number) => (get(record, 'Customer.CustomerCode') || ''),
            ...getColumnSearchProps('CustomerCode')
        },
        { 
            title: 'Invoice Code', 
            dataIndex: 'InvoiceCode', 
            key: 'InvoiceCode',
            ...getColumnSearchProps('InvoiceCode')
        },
        { 
            title: 'Total Amount', 
            dataIndex: 'TotalAmount', 
            key: 'TotalAmount',
            render: (text: any, record: Invoice, index: number) => (handleFormatCurrency(get(record, 'AmountOwing')).amountDisplay),
        },
    ];

    /**
     * Handle Table search
     * @param selectedKeys 
     * @param dataIndex 
     */
    const handleSearch = (selectedKeys: string[], dataIndex: string, confirm: any) => {
        confirm();
        dispatch(
            applyInvoiceHaveNoPaymentPlanFiltersAction({
                filterIndex: dataIndex,
                filterValue: selectedKeys[0]
            })
        );
    };

    /**
     * Handle reset table searching
     * @param dataIndex 
     * @param clearFilters 
     */
    const handleReset = (dataIndex: string, clearFilters: any) => {
        if (!hasFilters) return;

        clearFilters();
        dispatch(
            applyInvoiceHaveNoPaymentPlanFiltersAction({
                filterIndex: dataIndex,
                filterValue: undefined
            })
        );
    };

    /**
     * Handle select/unselect table row
     * @param selectedKey
     * @param selected 
     */
    const handleSelectTableRowKey = (selectedKey: InvoiceHaveNoPaymentPlanSelectedModel, selected: boolean) => {
        const selectedSet = new Set((get(tableRowSelection, 'selectedRowKeys') || []).map(
            (selected) => JSON.stringify(selected)
        ));
        const unselectedSet = new Set((get(tableRowSelection, 'unselectedRowKeys') || []).map(
            (unselected) => JSON.stringify(unselected)
        ))
        const stringSelectedKey = JSON.stringify(selectedKey);
        if (selected) {
            selectedSet.add(stringSelectedKey);
            unselectedSet.delete(stringSelectedKey);
        } else {
            unselectedSet.add(stringSelectedKey);
            selectedSet.delete(stringSelectedKey);
        }

        setTableRowSelection({
            ...tableRowSelection,
            selectedRowKeys: Array.from(selectedSet).map(selected => JSON.parse(selected)),
            unselectedRowKeys: Array.from(unselectedSet).map(unselected => JSON.parse(unselected))
        });
    }

    const handleFormSubmit = () => {
        const submitionFormData = {
            ...batchPaymentPlanFormData,
            excludedInvoices: get(tableRowSelection, 'unselectedRowKeys'),
        };
        if (!isUndefined(customerIds) && !isEmpty(customerIds)) {
            submitionFormData.customerIds = isCustomerSelectedAll 
                ? [] 
                : customerIds;
        }
        if (!isUndefined(invoiceIds) && !isEmpty(invoiceIds)) {
            submitionFormData.invoiceItems = isInvoicesSelectedAll 
                ? [] 
                : invoiceDataToSelectedRowKeys;
        }

        dispatch(updateBatchPaymentPlanFormDataAction(submitionFormData));
        handleNextStepFormClick();
    };

    /**
     * Init Table row selection
     */
    const rowSelection = {
        selectedRowKeys: (get(tableRowSelection, 'selectedRowKeys') || []).map((selected) => get(selected, 'InvoiceId')),
        onSelect: (
            record: Invoice, 
            selected: boolean, 
        ) => {
            handleSelectTableRowKey(
                Object.assign({}, {
                    InvoiceId: get(record, 'Id'),
                    CustomerId: get(record, 'Customer.Id'),
                    TotalAmount: get(record, 'AmountOwing')
                }), 
                selected);
        },
        onSelectAll: () => { return; },
        getCheckboxProps: (record: Invoice) => ({
            name: record.Id,
        }),
        columnTitle: (<span></span>)
    };

    const handleFormatCurrency = (amount: number) => {
            return {
                amountDisplay: formatCurrency ? formatCurrency(amount) : amount,
                currency: first(map(
                    filter(
                        (formatToParts ? formatToParts(amount) : []), p => p.type === 'currency'
                    ),
                    p => p.value
                ))
            };
        };

    /**
     * Fetch data for the first time
     */
    useEffect(() => {
        fetchInvoicesHaveNoPaymentPlan(0);
    }, []);

    /**
     * Fetch data When filters changing
     */
    useEffect(() => {
        let isUnmounted = false;
        if (!isUnmounted) {
            handleInvoiceData('filter changed')
        }

        return () => {
            isUnmounted = true
        };
    }, [filters]);

    /**
     * Table scroll listener
     */
    const tableBody = document.querySelector<HTMLTableSectionElement>('.scrolling-table .ant-table-body');
    useEffect(() => {
        if (tableBody) {
            tableBody.addEventListener('scroll', handleTableScroll);
        }

        return () => {
            if (tableBody) {
                tableBody.removeEventListener('scroll', handleTableScroll);
            }
        }
    }, [invoiceStates.data, tableBody]);
    
    /**
     * Table is scrolling to top when reset data
     */
    useEffect(() => {
        if (invoiceStates.pageData.currentPage === 0) {
            if (tableBody) {
                tableBody.scrollTop = 0;
            }
        }
    }, [invoiceStates.pageData.currentPage, tableBody]);

    /**
     * Auto select new data
     */
    useEffect(() => {
        let isUnmounted = false;
        if (!isUnmounted) {
            const selectedRowKeys = get(tableRowSelection, 'selectedRowKeys');
            let newSelectedRowKeys = uniqWith(
                concat(invoiceDataToSelectedRowKeys, selectedRowKeys),
                isEqual
            );
            const unselectedRowKeys = get(tableRowSelection, 'unselectedRowKeys');
            if (!isUndefined(unselectedRowKeys) && !isEmpty(unselectedRowKeys)) {
                newSelectedRowKeys = newSelectedRowKeys.filter(
                    (selectedKey: InvoiceHaveNoPaymentPlanSelectedModel) => !unselectedRowKeys.some(
                        (unselectedRowKey) => unselectedRowKey.InvoiceId === selectedKey.InvoiceId
                    )
                );
            }
            
            setTableRowSelection({
                ...tableRowSelection,
                selectedRowKeys: newSelectedRowKeys
            });
        }
        return () => {
            isUnmounted = true;
        }
    }, [invoiceStates.data]);

    return (
        <Spin spinning={invoiceStates.loading} tip={getTranslatedText('Fetching invoices...')}>
            <Row>
                <Col span={24}>
                    <Table
                        className='scrolling-table'
                        size='small'
                        rowSelection={rowSelection as any} 
                        columns={columns}
                        dataSource={invoiceData}
                        rowKey='Id'
                        pagination={false}
                        locale={{ emptyText: getTranslatedText('No Data') }}
                        scroll={{
                            y: '80vh',
                            scrollToFirstRowOnChange: true
                        }}
                    />
                </Col>
            </Row>
            <Row 
                justify='end'
                gutter={[16, 16]}
            >
                <Col 
                    span={24} 
                    className='ta-right'
                >
                    <Button 
                        onClick={handleFormSubmit}
                        type='primary'
                        disabled={get(tableRowSelection, 'selectedRowKeys').length === 0}
                        style={{ marginRight: '8px'}}
                    >
                        { getTranslatedText('Next') }
                    </Button>
                    <Button 
                        onClick={handleClosePanel} 
                        type='default'
                    >
                        { getTranslatedText('Cancel') }
                    </Button>
                </Col>
                
            </Row>
        </Spin>
    );
};

export default InvoiceNoPaymentPlanListComponent;